// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parkdale-js": () => import("./../../../src/pages/parkdale.js" /* webpackChunkName: "component---src-pages-parkdale-js" */),
  "component---src-pages-queen-and-bathurst-js": () => import("./../../../src/pages/queenAndBathurst.js" /* webpackChunkName: "component---src-pages-queen-and-bathurst-js" */),
  "component---src-pages-yorkville-js": () => import("./../../../src/pages/yorkville.js" /* webpackChunkName: "component---src-pages-yorkville-js" */)
}

